import {Connection} from 'ngsijs';
import {IEntity} from '../../models';

export interface IListEntitiesOptions<T extends IEntity> {
  /**
   *  Comma-separated list of attribute names whose data
   *  are to be included in the response. The attributes are retrieved in the
   *  order specified by this parameter. If this parameter is not included,
   *  the attributes are retrieved in arbitrary order.
   */
  attrs: string;
  /**
   *  Transaction id
   */
  correlator: string;
  /**
   *  Request total count
   */
  count: boolean;
  /**
   *  A comma-separated list of entity ids to retrieve.
   *  Incompatible with the `idPattern` option.
   */
  id: string;
  /**
   *  A correctly formated regular expression.
   *  Retrieve entities whose ID matches the regular expression. Incompatible
   *  with the `id` option
   */
  idPattern: string;
  /**
   *  This option allow you to specify the maximum number of entities you want to receive from the server
   *  Default is 20
   */
  limit: number;
  /**
   *  Allows you to skip a given number of elements at the beginning
   *  Default is 0
   */
  offset: number;
  /**
   *  A comma-separated list of metadata names to include in the response
   */
  metadata: string;
  /**
   *  A query expression for attribute metadata, composed of a list of statements separated by semicolons (`;`)
   */
  mq: string;
  /**
   *  Criteria for ordering results
   */
  orderBy: string;
  /**
   *  A query expression, composed of a list of statements separated by semicolons (`;`)
   */
  q: string;
  /**
   * Spatial relationship between matching entities and
   *   a reference shape. See "Geographical Queries" section in NGSIv2 specification
   *   for details.
   */
  georel: string;
  /**
   * Geographical area to which the query is restricted.
   * See "Geographical Queries" section in NGSIv2 specification for details.
   */
  geometry: string;
  /**
   * List of latitude-longitude pairs of coordinates
   * separated by ';'. See "Geographical Queries" section in NGSIv2 specification
   * for details.
   */
  coords: string;
  /**
   * Service/tenant to use in this operation
   */
  service: string;
  /**
   * Service path to use in this operation
   */
  servicePath: string;
  /**
   * A comma-separated list of entity types to retrieve
   */
  type: T['type'];
  /**
   * A correctly formatted regular expression.
   * Retrieve entities whose type matches the regular expression.
   * Incompatible with the `type` option.
   */
  typePattern: string;
  /**
   * Represent entities as an array of non-repeated
   *   attribute values.
   */
  unique: boolean;
}

export interface IListEntitiesResult<T, OptCount> {
  results: T[];
  limit: number;
  offset: number;
  count: OptCount extends true ? number : never;
  correlator: string;
}

export function listEntities<T extends IEntity>(
  connection: Connection,
  options: Partial<IListEntitiesOptions<T>>
): Promise<IListEntitiesResult<T, typeof options['count']>> {
  return connection.v2.listEntities({
    ...options,
    keyValues: true
  });
}
