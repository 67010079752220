export enum AssignmentEventType {
  MotionDetected = 'MotionDetected',
  NoMovement = 'NoMovement',
  CameraRotationDetected = 'CameraRotationDetected',
  SterileAreaEnter = 'SterileAreaEnter',
  SterileAreaLeave = 'SterileAreaLeave',
  BorderCrossed = 'BorderCrossed',
  DefocusDetected = 'DefocusDetected',
  DisappearObjectDetected = 'DisappearObjectDetected',
  AbandonedObjectsDetected = 'AbandonedObjectsDetected',
  VehicleTransitDetected = 'VehicleTransitDetected',
  // new types
  ImageTooBlurryDetected = 'ImageTooBlurryDetected',
  ImageTooBlurryReset = 'ImageTooBlurryReset',
  ImageTooDarkDetected = 'ImageTooDarkDetected',
  ImageTooDarkReset = 'ImageTooDarkReset',
  ImageTooBrightDetected = 'ImageTooBrightDetected',
  ImageTooBrightReset = 'ImageTooBrightReset',
  GlobalSceneChangeDetected = 'GlobalSceneChangeDetected',
  GlobalSceneChangeReset = 'GlobalSceneChangeReset',
  SignalLossDetected = 'SignalLossDetected',
  SignalLossReset = 'SignalLossReset',
  MotionAlarmDetected = 'MotionAlarmDetected',
  MotionAlarmReset = 'MotionAlarmReset'
}
