import {HubConnection, HubConnectionBuilder} from '@microsoft/signalr';
import {IEntity} from '../models';

enum HubMethods {
  Register = 'Register'
}

enum HubEvents {
  Notify = 'Notify'
}

export class NotificationSocket {
  private _clients = new Map<string, Set<(entity: any) => void>>();
  private _connection: HubConnection | null = null;
  private _destroyed = false;
  private _startPromise = Promise.resolve();

  @checkDestroyed()
  private onMessage(json: string) {
    try {
      const message: unknown = JSON.parse(json);
      if (typeof message === 'object' && message !== null) {
        if ('data' in message) {
          const entities = (message as {data: IEntity[]}).data;
          entities.forEach((entity: IEntity) => {
            const typeListeners = this._clients.get(entity.type);
            if (typeListeners) {
              typeListeners.forEach((notify) => notify(entity));
            }
          });
        }
      }
    } catch (e) {
      console.error('Unexpected message', e);
    }
  }

  constructor(private url: string) {}

  get destroyed(): boolean {
    return this._destroyed;
  }

  @checkDestroyed()
  addListener<T extends IEntity>(type: T['type'], func: (entity: T) => void): void {
    let set = this._clients.get(type);
    if (!set) {
      set = new Set();
      this._clients.set(type, set);
    }
    set.add(func);
  }

  @checkDestroyed()
  removeListener<T extends IEntity>(type: T['type'], func: (entity: T) => void): void {
    const set = this._clients.get(type);
    if (set) {
      set.delete(func);
    }
  }

  @checkDestroyed()
  open(): void {
    if (this._connection === null) {
      const connection = new HubConnectionBuilder().withUrl(this.url).withAutomaticReconnect().build();
      connection.on(HubEvents.Notify, this.onMessage.bind(this));
      this._connection = connection;
      this._startPromise = connection.start();
      this._startPromise
        .then(() => connection.invoke(HubMethods.Register))
        .catch((err) => {
          console.error(err);
          this.close();
        });
    }
  }

  close(): void {
    if (this._connection !== null) {
      const connection = this._connection;
      this._connection = null;
      this._destroyed = true;
      this._clients.clear();
      this._startPromise.then(() => connection.stop());
    } else {
      console.error('Socket is already closed');
    }
  }
}

function checkDestroyed<T extends NotificationSocket>() {
  return function (target: T, propertyKey: string, descriptor: PropertyDescriptor) {
    const original = descriptor.value;
    descriptor.value = function (this: T, ...args: any[]) {
      if (this.destroyed) {
        console.error('Socket was destroyed');
      } else {
        return original.apply(this, args);
      }
    };
  };
}
