// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const once = <F extends (...args: any[]) => any>(func: F): F => {
  let fired = false;
  return new Proxy(func, {
    apply(target, thisArg, argArray) {
      if (fired) {
        return;
      } else {
        fired = true;
      }
      return Reflect.apply(target, thisArg, argArray);
    }
  });
};
