export interface IServerContext {
  gatewayPath: string;
  service: string;
  servicePath: string;
  defaultService?: string;
  useMultiservice?: boolean;
}

export type IServiceOptions = Pick<IServerContext, 'service' | 'servicePath'>;

export const getServiceOptions = (context: IServerContext): IServiceOptions => ({
  service: context.service,
  servicePath: context.servicePath
});

export const makeGetFiwareHeaders = (getContext: () => IServerContext) => (): [string, string][] => {
  const context = getContext();
  return [
    ['fiware-service', context.service],
    ['fiware-servicePath', context.servicePath]
  ];
};
