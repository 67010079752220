import {keys} from './utils';

// protected -> requires valid cookie

const basePaths = {
  login: {
    value: '/auth/login',
    protected: false
  },
  userInfo: {
    value: '/auth/user/info',
    protected: true
  },
  userPermissions: {
    value: '/auth/user/permissions',
    protected: true
  },
  logout: {
    value: '/auth/logout',
    protected: true
  },
  /** @deprecated */
  orionVersion: {
    value: '/orion/version',
    protected: false
  },
  serverInfo: {
    value: '/serverInfo',
    protected: false
  },
  coordinator: {
    value: '/coordinator',
    protected: true
  },
  cameras: {
    value: '/cameras',
    protected: true
  },
  media: {
    value: '/media',
    protected: true
  },
  notifications: {
    value: '/notifications',
    protected: true
  },
  notificationSocket: {
    value: '/notifications/notification',
    protected: true
  }
};

type Paths = typeof basePaths;

export function getPaths(gatewayEndpoint: string): Paths {
  return keys(basePaths).reduce((acc, key) => {
    const path = basePaths[key];
    acc[key] = {
      ...path,
      value: gatewayEndpoint + path.value
    };
    return acc;
  }, {} as Paths);
}
