import {Connection} from 'ngsijs';

export interface INGSIConnectionOptions {
  servicePath: string;
  service: string;
}

export class NGSIConnectionPool {
  private _connections = new Map<string, Connection>();

  get(path: string, options: INGSIConnectionOptions): Connection {
    const {service, servicePath} = options;
    const connectionMap = this._connections;

    let connection: Connection;
    const key = `${path}:${service}:${servicePath}`;
    const existingConnection = connectionMap.get(key);
    if (existingConnection) {
      connection = existingConnection;
    } else {
      const newConnection = new Connection(`${window.location.origin}${path}`, options);
      connectionMap.set(key, newConnection);
      connection = newConnection;
    }
    return connection;
  }
}
