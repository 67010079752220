import {NotificationSocket} from './NotificationSocket';
import {IEntity} from '../models';
import {Connection} from 'ngsijs';
import {once} from '../utils';

/**
 *  Keeps WS connection closed unless there is at least one listener
 */

export class SharedNotificationSocket {
  private _socket: NotificationSocket | null = null;
  private _totalNumberOfClients = 0;

  private getSocket(): NotificationSocket {
    if (!this._socket) {
      const socket = new NotificationSocket(this._url);
      socket.open();
      this._socket = socket;
    }
    return this._socket;
  }

  private tryCloseSocket(socket: NotificationSocket) {
    if (this._totalNumberOfClients === 0) {
      socket.close();
      if (this._socket === socket) {
        this._socket = null;
      }
    }
  }

  constructor(private _url: string, private _connection: Connection) {}

  addListener<T extends IEntity>(type: T['type'], func: (entity: T) => void): () => void {
    const socket = this.getSocket();

    this._totalNumberOfClients += 1;

    socket.addListener(type, func);

    return once(() => {
      socket.removeListener(type, func);

      this._totalNumberOfClients -= 1;
      this.tryCloseSocket(socket);
    });
  }
}
