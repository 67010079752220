import {Connection} from 'ngsijs';

export interface ICreateSubscriptionResult {
  correlator: string | null;
  subscription: {
    id: string;
  };
  location: string;
}

export interface ISubscription {
  subject: {
    entities: Array<{
      idPattern: string;
      type: string;
    }>;
    condition: {
      attrs: Array<any>;
    };
  };
  notification: {
    attrs: Array<any>;
  };
}

export interface ICreateSubscriptionOptions {
  skipInitialNotification: boolean;
  correlator: string;
  service: string;
  servicepath: string;
}

export function createSubscription(
  connection: Connection,
  subscription: string | ISubscription,
  options: Partial<ICreateSubscriptionOptions> = {}
): Promise<ICreateSubscriptionResult> {
  if (typeof subscription === 'string') {
    subscription = getDefaultSubscription(subscription);
  }
  return connection.v2.createSubscription(subscription, {
    ...options,
    keyValues: true
  });
}

function getDefaultSubscription(type: string) {
  return {
    subject: {
      entities: [
        {
          idPattern: '.*',
          type
        }
      ],
      condition: {
        attrs: []
      }
    },
    notification: {
      attrs: []
    }
  };
}
