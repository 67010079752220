type Simple = number | string | boolean;

type Complex = {[k: string]: Complex | Simple} | Array<Complex | Simple>;

type Serializable = Simple | Complex;

function keys<T extends {[k: string]: any}>(obj: T): Array<keyof T> {
  return Object.keys(obj);
}

function sortObject<T extends Complex>(obj: T): T {
  return keys(obj)
    .sort()
    .reduce((result, key) => {
      const value = obj[key];
      result[key] = typeof value === 'object' ? sortObject(value as any) : value;
      return result;
    }, {} as T);
}

function buildKey(args: Serializable[]): string {
  return JSON.stringify(sortObject(args));
}

type MemoCache<V> = Map<string, {expiration: number; value: V}>;

/**
 * Memoization wrapper
 *
 * Supports expiration in milliseconds for cache entries. If expirationInterval is 0  - expiration is disabled.
 *
 * Supports custom resolver. The default resolver serializes arguments with JSON.stringify with object's keys sorted alphabetically.
 *
 * Be careful with default arguments, since they are not included in the function's [arguments] list (JS limitation).
 * You will need a custom resolver, if you need to deal with them.
 *
 */
export function memoize<P extends any[], R extends any, F extends (...args: P) => R>(
  func: F,
  expirationInterval = 0,
  resolver: (args: P) => string = buildKey
): F {
  const cache: MemoCache<R> = new Map();
  return new Proxy(func, {
    apply: function (target, thisArg, argArray) {
      const key = resolver(argArray as P);
      const match = cache.get(key);
      if (match) {
        if (expirationInterval <= 0 || performance.now() < match.expiration) {
          return match.value;
        } else {
          cache.delete(key);
        }
      }
      const res = Reflect.apply(target, thisArg, argArray);
      cache.set(key, {
        expiration: performance.now() + expirationInterval,
        value: res
      });
      return res;
    }
  });
}
